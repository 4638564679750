import React, {useState} from "react";
import {
    ChevronDownIcon,
    FormControl,
    ListSubheader,
    MenuItem,
    Select,
    Typography
} from "convertupleads-theme";
import {CustomTextField} from "../../../../../BulkEmailStyle.tsx";

const StyledMenuProps = {
    PaperProps: {
        sx: {
            maxHeight: 300,
            '& .MuiListSubheader-root': {
              paddingTop: '12px',
            },
            '& .MuiMenuItem-root': {
                padding: '10px 20px',
            },
            '& .MuiList-root': {
                padding: 0,
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 transparent',
            '&::-webkit-scrollbar': {
                width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
            },
        },
    },
};

interface ISelectWithSearchBoxProps {
    options: { label: string; options: { label: string; value: string; }[]; }[];
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    fieldMap?: { label: string; value: string; };
    emptyLabel?: string;
}

const SelectWithSearchBox: React.FC<ISelectWithSearchBoxProps> = ({ options, value, onChange, disabled, emptyLabel }) => {
    const [search, setSearch] = useState<string>("");

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const filter = (data: { label: string; options: { label: string; value: string; }[]; }[], searchLabel: string) => {
        if (searchLabel === "") {
            return data;
        }

        const regex = new RegExp(searchLabel, "i");

        const results: { label: string; options: { label: string; value: string; }[]; }[] = [];

        data.forEach(dItem => {
            const matchingOptions = dItem.options.filter(option => regex.test(option.label));
            if (matchingOptions.length > 0) {
                results.push({
                    label: dItem.label,
                    options: matchingOptions
                });
            }
        });

        return results;
    };

    const menuItems = () => {
        let searchedOptions = filter(options, search);

        return searchedOptions.map((option, index) => {
            let menuItems = [];

            menuItems.push(<Typography key={`label-${index}`} sx={{
                padding: "16px 16px 4px 20px",
                fontSize: "11px",
                textTransform: "uppercase",
                color: "#2d8688"
            }}>{option.label}</Typography>);
            menuItems.push(
                option.options.map((subOption, subIndex) => {
                    return (
                        <MenuItem key={`sub-${subIndex}`} value={subOption.value}>
                            {subOption.label}
                        </MenuItem>
                    );
                })
            );

            return menuItems;
        });
    };

    return (
        <FormControl fullWidth>
            <Select
                fullWidth
                value={value}
                defaultValue={''}
                onChange={onChange}
                IconComponent={ChevronDownIcon}
                MenuProps={StyledMenuProps}
                disabled={disabled}
                displayEmpty
            >
                <ListSubheader
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                    }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                    }}
                >
                    <CustomTextField
                        size="small"
                        placeholder="Search..."
                        fullWidth
                        value={search}
                        onChange={handleSearchChange}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            event.stopPropagation();
                        }}
                    />
                </ListSubheader>
                <MenuItem value={value === null ? null : ''}>{emptyLabel || 'Select option'}</MenuItem>
                {menuItems()}
            </Select>
        </FormControl>
    );
};

export default SelectWithSearchBox;