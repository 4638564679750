import {ICSVFile, IMapping, IMapData} from "../../../../../../../state/features/emailCampaign/emailCampaign.interface.ts";

const mapData = (data: ICSVFile<string | number>[][]) => {
    const rowsArr: IMapping[] = [] as IMapping[];
    const maxRow = 5;
    data.slice(0, 5)?.forEach((rowData, rowIndex) => {
        if (rowIndex >= maxRow) {
            return;
        }
        if (rowIndex == 0) {
            rowData?.forEach((eachData: any, dataIndex) => {
                rowsArr.push({
                    index: dataIndex,
                    fields: [eachData],
                });
            });
        } else {
            rowData.forEach((eachData: any, dataIndex) => {
                if (rowsArr[dataIndex] !== undefined && rowsArr[dataIndex] != null) {
                    rowsArr[dataIndex].fields.push(eachData);
                }
            });
        }
    });
    const mappedData = getMappedData(rowsArr);
    return {
        mappedRows: mappedData.mappedRows,
        actionNeededRows: mappedData.actionNeededRows,
        selectedRows: mappedData.selectedRows,
        submitData: mappedData.submitData,
    };
};

const getMappedData = (allRows: IMapping[]) => {
    const mappedRows: IMapping[] = [];
    const actionNeededRows: IMapping[] = [];
    const submitData: IMapData[] = [] as IMapData[];
    const selectedRows: { [key: number]: string } = {};

    allRows.forEach((row: IMapping) => {
        let has = false;
        for (const [index, data] of row.fields.entries()) {
            if (isPhoneNumber(index, data) && !Object.values(selectedRows).includes('number')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'number';
                has = true;
                break;
            } else if (isEmail(index, data) && !Object.values(selectedRows).includes('email')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'email';
                has = true;
                break;
            } else if (isZip(index, data) && !Object.values(selectedRows).includes('zip')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'zip';
                has = true;
                break;
            }else if (isFirstName(index, data) && !Object.values(selectedRows).includes('first_name')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'first_name';
                has = true;
                break;
            }else if (isLastName(index, data) && !Object.values(selectedRows).includes('last_name')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'last_name';
                has = true;
                break;
            }else if (isLeadType(index, data) && !Object.values(selectedRows).includes('lead_type')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'lead_type';
                has = true;
                break;
            }else if (isCountry(index, data) && !Object.values(selectedRows).includes('country')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'country';
                has = true;
                break;
            }else if (isCity(index, data) && !Object.values(selectedRows).includes('city')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'city';
                has = true;
                break;
            }else if (isAddress(index, data) && !Object.values(selectedRows).includes('address')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'address';
                has = true;
                break;
            }else if (isState(index, data) && !Object.values(selectedRows).includes('state')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'state';
                has = true;
                break;
            }else if (isCompanyName(index, data) && !Object.values(selectedRows).includes('company_name')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'company_name';
                has = true;
                break;
            }else if (isAnniversaryDate(index, data) && !Object.values(selectedRows).includes('anniversary_date')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'anniversary_date';
                has = true;
                break;
            }else if (isBirthDate(index, data) && !Object.values(selectedRows).includes('birth_date')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'birth_date';
                has = true;
                break;
            }else if (isUrl(index, data) && !Object.values(selectedRows).includes('url')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'url';
                has = true;
                break;
            }
        }
        if (!has) {
            actionNeededRows.push(row);
            submitData[row.index] = {
                type: 'contact',
                map: row.index,
                data: null,
            };
        } else {
            submitData[row.index] = {
                type: 'contact',
                map: row.index,
                data: selectedRows[row.index],
            };
        }
    });

    return {
        mappedRows: mappedRows,
        actionNeededRows: actionNeededRows,
        selectedRows: selectedRows,
        submitData: submitData,
    };
};

const isPhoneNumber = (index, phone) => {
    if(index === 0){
        const HEADER_PHONE_REGEX = /\b(phone|number|phone_number|contact_number|mobile_number|telephone_number|telephone)\b/i;

        return phone.match(HEADER_PHONE_REGEX);
    }

    if (index === 1) {
        const US_PHONE_REGEX = /^\+?[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;

        return phone.match(US_PHONE_REGEX);
    }

    return false;
};

const isFirstName = (index, name) => {
    if (index === 0) {
        const HEADER_FIRST_NAME_REGEX = /\b(first[_\s]?name|f[_\s]?name)\b/i;

        return name.match(HEADER_FIRST_NAME_REGEX);
    }

    return false;
};

const isLastName = (index, name) => {
    if (index === 0) {
        const HEADER_LAST_NAME_REGEX = /\b(last[_\s]?name|l[_\s]?name)\b/i;

        return name.match(HEADER_LAST_NAME_REGEX);
    }

    return false
};

const isEmail = (index, email) => {
    if(index === 0){
        const HEADER_EMAIL_REGEX = /\b(email|contact[-_\s]?email|email[-_\s]?address)\b/i;

        return email.match(HEADER_EMAIL_REGEX);
    }

    if (index === 1) {
        const PYPEPRO_STANDARD_EMAIL_REGEX = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

        return PYPEPRO_STANDARD_EMAIL_REGEX.test(String(email).toLowerCase());
    }

    return false;
};

const isZip = (index: number, zip: string) => {
    if(index === 0){
        const HEADER_EMAIL_REGEX = /\b(zip|zipcode|zip[_\s]?code|postal|postal[_\s]?code)\b/i;

        return zip.match(HEADER_EMAIL_REGEX);
    }

    if (index === 1) {
        const PYPEPRO_STANDARD_ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

        return PYPEPRO_STANDARD_ZIP_REGEX.test(zip);
    }

    return false;
};

const isCountry = (index: number, country: string) => {
    if(index === 0){
        const HEADER_COUNTRY_REGEX = /\b(country|country[_\s]?name)\b/i;

        return country.match(HEADER_COUNTRY_REGEX);
    }

    return false;
};

const isCity = (index: number, city: string) => {
    if(index === 0){
        const HEADER_CITY_REGEX = /\b(city|city[_\s]?name)\b/i;

        return city.match(HEADER_CITY_REGEX);
    }

    return false;
};

const isState = (index: number, state: string) => {
    const HEADER_STATE_REGEX = /\b(state|state[_\s]?name)\b/i;

    if(index === 0){
        return state.match(HEADER_STATE_REGEX);
    }
};

const isAddress = (index: number, address: string) => {
    if(index === 0){
        const HEADER_ADDRESS_REGEX = /\b(address|street[_\s]?address)\b/i;

        return address.match(HEADER_ADDRESS_REGEX);
    }

    return false;
};

const isAnniversaryDate = (index: number, anniversaryDate: string) => {
    if(index === 0){
        const HEADER_ANNIVERSARY_DATE_REGEX = /\b(anniversary|anniversary[_\s]?date|anniversarydate)\b/i;

        return anniversaryDate.match(HEADER_ANNIVERSARY_DATE_REGEX);
    }

    return false;
};

const isBirthDate = (index: number, birthDate: string) => {
    if(index === 0){
        const HEADER_BIRTH_DATE_REGEX = /\b(birth|birth[_\s]?date|birthdate)\b/i;

        return birthDate.match(HEADER_BIRTH_DATE_REGEX);
    }

    return false;
};

const isUrl = (index: number, url: string) => {
    if(index === 0){
        const HEADER_URL_REGEX = /\b(url)\b/i;

        return url.match(HEADER_URL_REGEX);
    }

    return false;
};

const isLeadType = (index: number, leadType: string) => {
    if (index === 0) {
        const HEADER_LEAD_TYPE_REGEX = /\b(lead[_\s]?type|leadtype)\b/i;

        return leadType.match(HEADER_LEAD_TYPE_REGEX);
    }

    return false;
};

const isCompanyName = (index: number, companyName: string) => {
    if (index === 0) {
        const HEADER_COMPANY_NAME_REGEX = /\b(company|company[_\s]?name|companyname)\b/i;

        return companyName.match(HEADER_COMPANY_NAME_REGEX);
    }

    return false;
};

const mapCSVImportHelper = {
    mapData,
};

export default mapCSVImportHelper;