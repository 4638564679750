import React, { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { IMapData, IMapping } from "./connect/mapping/Mapping.tsx";
import {
    CONNECTED_FILE_STEPS,
    IMPORT_CONTACT_V2_COMMUNICATION_EMAIL,
    IMPORT_CONTACT_V2_CONTACT_ADDRESS,
    IMPORT_CONTACT_V2_CONTACT_DETAILS,
    IMPORT_CONTACT_V2_PRIMARY_PHONE,
    IMPORT_CONTACT_V2_RELATED_CONTACT1,
    IMPORT_CONTACT_V2_RELATED_CONTACT2,
    IMPORT_CONTACT_V2_RELATED_CONTACT3,
    IMPORT_CONTACT_V2_RELATED_CONTACT4,
    IMPORT_CONTACT_V2_RELATED_CONTACT5,
    IMPORT_CONTACT_V2_SECONDARY_PHONE1,
    IMPORT_CONTACT_V2_SECONDARY_PHONE2,
    IMPORT_CONTACT_V2_SECONDARY_PHONE3,
    IMPORT_CONTACT_V2_SECONDARY_PHONE4
} from "../../../../../helpers/constant/coreConstants.ts";
import { showNotification } from "../../../../../helpers/util/functions.tsx";
import emailCampaignService from "../../../../../services/emailCampaign.service.ts";

interface ImportSpreadsheetContextType {
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const ImportSpreadsheetContext = createContext<ImportSpreadsheetContextType | undefined>(undefined);

interface ImportSpreadsheetContextProviderProps {
    children: ReactNode;
}

interface IAdditionalInfos {
    tag_ids?: number[];
    stage_id?: number;
    pipeline_id?: number;
    source_id?: number;
    lookup_type?: any[];
    search_category?: any[];
    ignore_first_row: boolean;
}

const initialMappingFields = [
    {
        label: "Details",
        options: IMPORT_CONTACT_V2_CONTACT_DETAILS,
    },
    {
        label: "Emails",
        options: IMPORT_CONTACT_V2_COMMUNICATION_EMAIL
    },
    {
        label: "Primary Phone",
        options: IMPORT_CONTACT_V2_PRIMARY_PHONE
    },
    {
        label: "Phone (1)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE1
    },
    {
        label: "Phone (2)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE2
    },
    {
        label: "Phone (3)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE3
    },
    {
        label: "Phone (4)",
        options: IMPORT_CONTACT_V2_SECONDARY_PHONE4
    },
    {
        label: "Address",
        options: IMPORT_CONTACT_V2_CONTACT_ADDRESS,
    },
    {
        label: "Related Contacts (1)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT1
    },
    {
        label: "Related Contacts (2)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT2
    },
    {
        label: "Related Contacts (3)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT3
    },
    {
        label: "Related Contacts (4)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT4
    },
    {
        label: "Related Contacts (5)",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT5
    }
];

const ImportSpreadsheetContextProvider: React.FC<ImportSpreadsheetContextProviderProps> = ({ children }) => {
    const [screen, setScreen] = useState<number>(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST);
    const [activeImportStep, setActiveImportStep] = useState<number>(0);
    const [valuesForSubmit, setValuesForSubmit] = useState<IMapData[]>([] as IMapData[]);
    const [valuesForSelect, setValuesForSelect] = useState<IMapData[]>([] as IMapData[]);
    const [mappedRows, setMappedRows] = useState<IMapping[]>([] as IMapping[]);
    const [unMappedRows, setUnMappedRows] = useState<IMapping[]>([] as IMapping[]);
    const [mappedFields, setMappedFields] = useState<{ [key: number]: string }>({});
    const [email, setEmail] = useState('');
    const [link, setLink] = useState('');
    const [fileName, setFileName] = useState('');
    const [worksheet, setWorksheet] = useState('');
    const [worksheets, setWorksheets] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [stages, setStages] = useState([]);
    const [tags, setTags] = useState([]);
    const [leadSources, setLeadSources] = useState([]);
    const [selectedConnectFile, setSelectedConnectFile] = useState(null);
    const [additionalInfos, setAdditionalInfos] = useState<IAdditionalInfos>({
        ignore_first_row: false,
    });
    const [mappingColumns, setMappingColumns] = useState(initialMappingFields);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const customFieldsResp = await emailCampaignService.getContactCustomFields();
            if (customFieldsResp?.success && customFieldsResp?.data && Array.isArray(customFieldsResp.data)) {
                let data = customFieldsResp.data;

                let newCFields = [...mappingColumns];
                let formattedCustomFields: { label: string; value: string }[] = [];

                data.forEach((item: any) => {
                    formattedCustomFields.push({
                        label: item.title,
                        value: `custom_${item.id}`
                    });
                });

                newCFields.push({
                    label: "Custom Fields",
                    options: formattedCustomFields
                });

                setMappingColumns(newCFields);
            }
        } catch (err) {
            console.log(err)
        }

        try {
            const tagsResp = await emailCampaignService.getTriggerTagList();
            if (tagsResp?.success) {
                setTags(tagsResp.data);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const sourcesResp = await emailCampaignService.getContactSources();
            if (sourcesResp?.success) {
                setLeadSources(sourcesResp.data);
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const pipelinesResp = await emailCampaignService.getContactPipelines();
            if (pipelinesResp?.success) {
                setPipelines(pipelinesResp.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const stepList = [
        {
            label: "Connect",
        },
        {
            label: "Configure",
        },
        {
            label: "Mapping",
        },
        {
            label: "Details",
        },
    ];

    const findSelectedValue = (index: number): string => {
        return valuesForSelect[index]?.data as string;
    };

    const getLabel = (value: string) => {
        try {
            return mappingColumns.find((mappingColumn) => (mappingColumn.value === value))?.label || 'this field';
        } catch (err) {
            return 'this field';
        }
    };

    const handleMapColumn = (value: string, index: number) => {
        let currentValue = valuesForSelect[index].data;

        if (value !== currentValue) {
            let mf = { ...mappedFields };

            mf[index] = value;

            const oldValuesForSubmit = [...valuesForSubmit];
            const oldValuesForSelect = [...valuesForSelect];

            if (value && value.split('_')[0] === "custom") {
                oldValuesForSelect[index] = { type: 'custom', map: index, data: value as string };
            } else {
                oldValuesForSelect[index] = { type: 'contact', map: index, data: value as string };
            }

            oldValuesForSelect[index] = { data: value as string };

            if (valuesForSubmit[index].data == null) {
                const filterUnmap = unMappedRows?.filter((item) => item.index != index);
                const filterMap = unMappedRows?.find((item) => item.index === index);
                setUnMappedRows(filterUnmap);
                setMappedRows((prevData) => [...prevData, filterMap as IMapping]);
            }

            if (value && value.split('_')[0] === "custom") {
                oldValuesForSubmit[index] = { type: 'custom', map: index, data: value.replace("custom_", "") as string };
            } else {
                oldValuesForSubmit[index] = { type: 'contact', map: index, data: value as string };
            }

            setMappedFields(mf);
            setValuesForSubmit(oldValuesForSubmit);
            setValuesForSelect(oldValuesForSelect);
        }
    };

    const handleGoNextStep = () => {
        setActiveImportStep((prevActiveImportStep) => prevActiveImportStep + 1);
    };

    const handleGoPreviousStep = () => {
        setActiveImportStep((prevActiveImportStep) => prevActiveImportStep - 1);
    };

    const goToStep = (step: number) => {
        setActiveImportStep(step);
    };

    const changeScreen = (mScreen: number) => {
        setScreen(mScreen);
    };

    const extractFileId = (url: string) => {
        const regex = /\/d\/([a-zA-Z0-9_-]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const resetImportData = () => {
        setLink('');
        setEmail('');
        setFileName('');
        setWorksheet('');
        setWorksheets([]);
        setMappedRows([]);
        setUnMappedRows([]);
        setValuesForSubmit([]);
        setAdditionalInfos({
            ignore_first_row: false,
        });
    };

    const changeAdditionalInfos = (newState: IAdditionalInfos) => {
        setAdditionalInfos((prevState) => ({ ...prevState, ...newState }));
    };

    const handleSelectPipeline = async (pipeline_id: number) => {
        setAdditionalInfos((prevState) => ({ ...prevState, pipeline_id: pipeline_id, stage_id: undefined }));
        setStages([]);
        try {
            const pipelineResp = await emailCampaignService.getContactPipelineStages({ pipelineId: pipeline_id });
            if (pipelineResp?.success) {
                setStages(pipelineResp.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const reFormat = (data: any, type: string) => {
        const result = {} as any;

        for (const key in data) {
            const dataType = data[key].data;
            const keyType = data[key].type;

            if (dataType && keyType === type) {
                result[dataType] = parseInt(key);
            }
        }

        return result;
    };

    const filteredMappedFields = (i: number) => {
        let alreadyMappedFields = valuesForSubmit.map((item: { type: string; map: number; data: string; })=>(item.data)).filter(Boolean);

        return mappingColumns.map((item: { label: string, value?: string; options?: { label: string; value: string; }[] }) => {
            if(item.options){
                const filteredOptions = item.options.filter(option => (!alreadyMappedFields.includes(option.value) || mappedFields[i] === option.value));

                if (filteredOptions.length > 0) {
                    return { ...item, options: filteredOptions };
                }

                return null;
            }else {
                if ((!alreadyMappedFields.includes(item.value) || mappedFields[i] === item.value || item.value === "")) {
                    return { ...item };
                }
                return null;
            }
        }).filter(Boolean);
    };

    return (
        <ImportSpreadsheetContext.Provider
            value={{
                fileName,
                setFileName,
                stepList,
                activeImportStep,
                handleGoNextStep,
                handleGoPreviousStep,
                screen,
                changeScreen,
                mappedRows,
                unMappedRows,
                valuesForSubmit,
                setMappedRows,
                setUnMappedRows,
                setValuesForSubmit,
                findSelectedValue,
                handleMapColumn,
                email,
                setEmail,
                link,
                setLink,
                extractFileId,
                worksheet,
                setWorksheet,
                worksheets,
                setWorksheets,
                goToStep,
                resetImportData,
                mappingColumns,
                setMappingColumns,
                setValuesForSelect,
                additionalInfos,
                changeAdditionalInfos,
                tags,
                setTags,
                leadSources,
                pipelines,
                stages,
                handleSelectPipeline,
                selectedConnectFile,
                setSelectedConnectFile,
                reFormat,
                mappedFields,
                setMappedFields,
                filteredMappedFields
            }}
        >
            {children}
        </ImportSpreadsheetContext.Provider>
    );
};

export const useImportSpreadsheetContext = (): ImportSpreadsheetContextType => {
    const context = useContext(ImportSpreadsheetContext);

    if (!context) {
        throw new Error("useImportSpreadsheetContext must be used within a ImportSpreadsheetContextProvider");
    }

    return context;
};

export const withImportSpreadsheetContext = <P extends object>(Component: React.ComponentType<P>) => {
    const WithImportSpreadsheetContext: React.FC<P> = (props) => (
        <ImportSpreadsheetContextProvider>
            <Component {...props} />
        </ImportSpreadsheetContextProvider>
    );

    return WithImportSpreadsheetContext;
};