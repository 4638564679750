import React, {useEffect, useState} from "react";
import {Box, Button, CheckCircleIcon, InfoCircleIcon, Paper, Stack, Tab, Tabs, Typography} from "convertupleads-theme";
import {MapFieldTable, TabLabel} from "../connect/mapping/MappingStyles";
import {CustomScrollbar} from "../../../../../common/CustomScrollBarStyle";
import SelectWithSearchBox from "../connect/mapping/SelectWithSearchBox";
import {useImportSpreadsheetContext} from "../importSpreadsheetReducer.tsx";
import {IMapData} from "../connect/mapping/Mapping.tsx";
import emailCampaignService from "../../../../../../services/emailCampaign.service.ts";
import {showNotification} from "../../../../../../helpers/util/functions.tsx";
import {CONNECTED_FILE_STEPS} from "../../../../../../helpers/constant/coreConstants.ts";
import {useSearchParams} from "react-router-dom";

const emptyObjToNull = (x: any) => {
    if (Object.keys(x).length === 0) {
        return  null;
    }

    return x;
};

const Sync = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        mappedRows,
        unMappedRows,
        valuesForSubmit,
        handleMapColumn,
        findSelectedValue,
        changeScreen,
        selectedConnectFile,
        setMappedRows,
        setUnMappedRows,
        setValuesForSubmit,
        setValuesForSelect,
        reFormat,
        filteredMappedFields,
        setMappedFields
    } = useImportSpreadsheetContext();
    const [activeMappingStep, setActiveMappingStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setActiveMappingStep(newValue);
    };

    useEffect(() => {
        if (selectedConnectFile && selectedConnectFile.mapping){
            const result: IMapData[] = [];
            const mf: { [key: number]: string; } = {};

            for (const [key, value] of Object.entries(selectedConnectFile.mapping)) {
                if (Array.isArray(value)) {
                    value.forEach(v => {
                        result.push({ type: "contact", map: v, data: key });
                    });
                } else {
                    result.push({ type: "contact", map: value, data: key });
                }
                mf[value] = key;
            }

            if (selectedConnectFile.customMapping){
                for (const [key, value] of Object.entries(selectedConnectFile.customMapping)) {
                    result.push({ type: "custom", map: value, data: `custom_${key}` });
                    mf[value] = key;
                }
            }

            setValuesForSelect((prevState: IMapData[])=>{
                const dataMap: Map<number, IMapData> = new Map(prevState.map((item) => [item.map, item]));

                result.forEach(item => {
                    if (dataMap.has(item.map)) {
                        dataMap.set(item.map, item);
                    }
                });

                const mergedData: IMapData[] = Array.from(dataMap.values()).sort((a, b) => (a.map - b.map));

                return mergedData;
            });

            setValuesForSubmit((prevState: IMapData[])=>{
                const dataMap: Map<number, IMapData> = new Map(prevState.map((item) => [item.map, item]));

                result.forEach(item => {
                    if (dataMap.has(item.map)) {
                        const data = item.type === 'custom' ? item.data?.replace(/^custom_/, "") : item.data;
                        dataMap.set(item.map, { ...item, data });
                    }
                });

                const mergedData: IMapData[] = Array.from(dataMap.values()).sort((a, b) => (a.map - b.map));

                return mergedData;
            });

            const savedMapSet: Set<number> = new Set(result.map((item) => (item.map)));

            let mappedData: IMapData[] = [...mappedRows];
            let unmappedData: IMapData[] = [];

            unmappedData = unMappedRows.filter((entry: IMapData) => {
                if (savedMapSet.has(entry.index)) {
                    mappedData.push(entry);
                    return false;
                }
                return true;
            });

            setUnMappedRows(unmappedData);
            setMappedRows(mappedData);
            setMappedFields(mf);
        }
    }, [selectedConnectFile]);

    const handleSaveUpdateData = async () => {
      try{
          setSubmitting(true);
          const resp = await emailCampaignService.updateUserConnectedFiles(selectedConnectFile?.uid,{
              providerFileName: selectedConnectFile?.providerFileName,
              mapping: reFormat(valuesForSubmit, "contact"),
              customMapping: emptyObjToNull(reFormat(valuesForSubmit, "custom")),
              additionalInfos: selectedConnectFile?.additionalInfos,
          });

          if (resp?.success){
            showNotification("success", resp.message);
            changeScreen(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST);

              if (searchParams.has('spreadsheet')) {
                  searchParams.delete('spreadsheet');
              }

              if (searchParams.has('state')) {
                  searchParams.delete('state');
              }

              setSearchParams(searchParams);
          }else {
              showNotification("error", "Failed to sync data");
          }
      }catch (err){
          console.log(err);
          showNotification("error", "Failed to sync data");
      }finally {
          setSubmitting(false);
      }
    };

    return(
        <Paper elevation={0} sx={{height: "92vh", m: 1, p: 2}}>
            <Stack direction={"row"} justifyContent={'center'} spacing={2} pt={0} pb={5} px={25}>
                <Box sx={{width: "60%"}}>
                    <Stack spacing={2}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography variant={"h5"} color={"text.primary"}>
                                Sync Spreadsheet
                            </Typography>
                            <Stack direction={'row'} spacing={1.5}>
                                <Button variant={'tonal'} onClick={()=>{
                                    changeScreen(CONNECTED_FILE_STEPS.CONNECTED_FILE_LIST);

                                    if (searchParams.has('spreadsheet')) {
                                        searchParams.delete('spreadsheet');
                                    }

                                    if (searchParams.has('state')) {
                                        searchParams.delete('state');
                                    }

                                    setSearchParams(searchParams);
                                }}>
                                    Cancel
                                </Button>
                                <Button disabled={submitting} onClick={handleSaveUpdateData}>
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                        <Tabs sx={{ marginTop: "10px !important" }} value={activeMappingStep} onChange={handleChange}>
                            <Tab sx={{ "&:focus": { background: "transparent" }}} label={<TabLabel title={"All"} value={mappedRows.length + unMappedRows.length} isSelected={activeMappingStep === 0} />} />
                            <Tab sx={{ "&:focus": { background: "transparent" }}} label={<TabLabel title={"Mapped"} value={mappedRows.length} isSelected={activeMappingStep === 1} />} />
                            <Tab sx={{ "&:focus": { background: "transparent" }}} label={<TabLabel title={"Unmapped"} value={unMappedRows.length} isSelected={activeMappingStep === 2} />} />
                        </Tabs>

                        <CustomScrollbar style={{ maxHeight: 460, overflow: "auto" }}>
                            <MapFieldTable>
                                <thead>
                                <tr>
                                    <th>
                                        <Typography variant={"overline"} color={"text.tertiary"}>
                                            mapped
                                        </Typography>
                                    </th>
                                    <th>
                                        <Typography variant={"overline"} color={"text.tertiary"}>
                                            column data
                                        </Typography>
                                    </th>
                                    <th width={"280px"}>
                                        <Typography variant={"overline"} color={"text.tertiary"}>
                                            list field
                                        </Typography>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {(activeMappingStep == 0 || activeMappingStep == 1) &&
                                    mappedRows?.map((item, index) => (
                                        <tr key={index} style={{ padding: "10px 10px" }}>
                                            <td style={{ padding: "20px 0px" }}>
                                                <CheckCircleIcon color={"success"} />
                                            </td>
                                            <td style={{ padding: "20px 0px" }}>
                                                <Stack>
                                                    {
                                                        item?.fields.map((field: string)=>(
                                                            <Typography variant={"body2"} fontWeight={"bold"}>
                                                                {field}
                                                            </Typography>
                                                        ))
                                                    }
                                                </Stack>
                                            </td>
                                            <td style={{ padding: "20px 0px" }}>
                                                <SelectWithSearchBox
                                                    options={filteredMappedFields(item?.index)}
                                                    value={findSelectedValue(item?.index)}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                                        handleMapColumn(event.target.value, item?.index);
                                                    }}
                                                    emptyLabel={'Don\'t import'}
                                                />
                                            </td>
                                        </tr>
                                    ))}

                                {(activeMappingStep == 0 || activeMappingStep == 2) &&
                                    unMappedRows?.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: "20px 0px" }}>
                                                <InfoCircleIcon color={"warning"} />
                                            </td>
                                            <td style={{ padding: "20px 0px" }}>
                                                <Stack>
                                                    {
                                                        item?.fields.map((field: string)=>(
                                                            <Typography variant={"body2"} fontWeight={"bold"}>
                                                                {field}
                                                            </Typography>
                                                        ))
                                                    }
                                                </Stack>
                                            </td>
                                            <td style={{ padding: "20px 0px" }}>
                                                <SelectWithSearchBox
                                                    options={filteredMappedFields(item?.index)}
                                                    value={findSelectedValue(item?.index)}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                                                        handleMapColumn(event.target.value, item?.index);
                                                    }}
                                                    emptyLabel={'Don\'t import'}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </MapFieldTable>
                        </CustomScrollbar>
                    </Stack>
                </Box>
            </Stack>
        </Paper>
    );
}

export default Sync;