export const STATUS = {
    PENDING: 0,
    PROCESSING: 1,
    COMPLETE: 2,
    FAILED: 3,
};

export const GLOBAL_DELAY = {
    DEBOUNCE: 1000,
    RESPONSE: 1000,
};

export const EDITOR_TYPE = {
    RICH_TEXT: 1,
    DRAG_DROP: 2,
    CUSTOM_HTML: 3,
};

export const EMAIL_SCHEDULE_TABS = {
    SAME_DAY: 1,
    LATER_DAY: 2,
    RECURRING: 3,
};

export const EMAIL_SCHEDULE_TYPE = {
    IMMEDIATELY: "IMMEDIATE",
    SCHEDULE: "SCHEDULE",
    RECURRING: "RECURRING",
    FIXED_DATE: "FIXED_DATE",
    BIRTHDAY: "BIRTHDAY",
    ANNIVERSARY: "ANNIVERSARY"
};

export enum STEPS {
    STEP_SEQUENCE = 1,
    STEP_SCHEDULE = 2,
    STEP_LEADS = 3,
    STEP_SETTINGS = 4
}

export const STEPS_SUB = {
    [STEPS.STEP_LEADS]: {
        SPREADSHEET: 1,
        CSV: 2,
    }
}

export enum CAMPAIGN_SEQUENCE_MESSAGE_TYPE {
    EMAIL = "EMAIL"
}

export const CAMPAIGN_TRIGGER_SETTINGS = {
    ADD_TO_ANOTHER_CAMPAIGN_SETTINGS: 'ADD_TO_ANOTHER_CAMPAIGN_SETTINGS',
    ADD_TO_POWER_DIALER_LIST_SETTINGS: 'ADD_TO_POWER_DIALER_LIST_SETTINGS',
    ADD_TAG_SETTINGS: 'ADD_TAG_SETTINGS',
    SEND_VOICE_MESSAGE: 'SEND_VOICE_MESSAGE',
    BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS: 'BOUNCE_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS',
    DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS: 'DELIVERY_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS',
    OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS: 'OPEN_RATE_WISE_CAMPAIGN_PAUSE_SETTINGS',
    CONTACT_REPLY_WISE_CAMPAIGN_PAUSE_SETTINGS: 'CONTACT_REPLY_WISE_CAMPAIGN_PAUSE_SETTINGS',
    SWITCH_EMAIL_SENEDER_SETTINGS: 'SWITCH_EMAIL_SENEDER_SETTINGS'
};

export const CAMPAIGN_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const CAMPAIGN_SETTINGS = {
    GLOBAL_SCHEDULE_STATUS: 'global-schedule',
    GLOBAL_SCHEDULE_START_DATE: 'global-schedule-start-date',
    GLOBAL_SCHEDULE_END_DATE: 'global-schedule-end-date',
    START_TIMING: 'start-timing',
    END_TIMING: 'end-timing',
    OPERATE_INDEPENDENTLY: 'operate-independently',
    TIMEZONE: 'timezone',
    INTERVAL_TIME_OPTION: 'interval-time-option',
    INTERVAL_TIME: 'interval-time',
    DAYS_SCHEDULE: 'days-schedule'
}

export const LOOKUP__ACTION_RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL: string = 'RUNTIME_LOOKUP_VALIDATION_FOR_EMAIL';
export const LOOKUP__ACTION_SEND_UNDELIVERABLE: string = 'SEND_UNDELIVERABLE';
export const LOOKUP__ACTION_SEND_DELIVERABLE: string = 'SEND_DELIVERABLE';
export const LOOKUP__ACTION_SEND_RISKY: string = 'SEND_RISKY';

export const TIMEZONE_LIST = {
    'America/New_York': 'Eastern Time',
    'America/Chicago': 'Central Time',
    'America/Denver': 'Mountain Time',
    'America/Phoenix': 'Arizona Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Anchorage': 'Alaska Time',
    'Pacific/Honolulu': 'Hawaii Time',
};

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_RUNNING = 'RUNNING';
export const STATUS_STARTING = 'STARTING';
export const STATUS_PAUSE = 'PAUSE';
export const STATUS_PAUSING = 'PAUSING';
export const STATUS_TRASH = 'TRASH';
export const STATUS_ARCHIVE = 'ARCHIVE';
export const STATUS_RESUME = 'RESUME';

export const STATUS_LABEL: { [key: string]: string } = {
    [STATUS_ACTIVE]: 'Pause',
    [STATUS_RUNNING]: 'Running',
    [STATUS_STARTING]: 'Starting',
    [STATUS_RESUME]: 'Starting',
    [STATUS_PAUSE]: 'Pause',
    [STATUS_PAUSING]: 'Pausing',
};

export const STATUS_UPDATE: { [key: string]: "RESUME" | "STARTING" | "PAUSE" | "PAUSING" | "ARCHIVE" } = {
    [STATUS_ACTIVE]: STATUS_RESUME,
    [STATUS_RUNNING]: STATUS_PAUSE,
    [STATUS_STARTING]: STATUS_STARTING,
    [STATUS_PAUSE]: STATUS_RESUME,
    [STATUS_PAUSING]: STATUS_PAUSING
};

export const EMAIL_THREAD = {
    SINGLE_THREAD: 'SINGLE_THREAD',
    MULTI_THREAD: 'MULTI_THREAD'
};

export const EMAIL_PROVIDER = {
    DEFAULT: 0,
    NYLAS_V1: 1,
    NYLAS_V3: 2
};

export const CONTACT_STATUS = {
    RUNNING: 'RUNNING',
    PAUSE: 'PAUSE',
    UNSUBSCRIBED: 'UNSUB',
    COMPLETE:'COMPLETE'
}

export const CAMPAIGN_SETTING_KEYS = {
    UNSUBSCRIBE_LIST: 'UNSUBSCRIBE_LIST'
}

export const TRACK_SETTINGS_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const DATABASE_TIMEZONE = 'UTC';

export const LEAD_ITEM = {
    CAMPAIGN_CONTACT_LIST: 'CAMPAIGN_CONTACT_LIST',
    ADD_FROM_CONTACT_LIST: 'ADD_FROM_CONTACT_LIST',
    IMPORT_CSV: 'IMPORT_CSV',
    SMART_LIST:'SMART_LIST',
    BULK_EMAIL_CAMPAIGN_CONTACT_LIST:'BULK_EMAIL_CAMPAIGN_CONTACT_LIST',
    MARKETING_CAMPAIGN_CONTACT_LIST:'MARKETING_CAMPAIGN_CONTACT_LIST',
    IMPORT_GOOGLE_SHEET:'IMPORT_GOOGLE_SHEET',

}

export const STATUS_TYPE = {
    OPEN: "OPEN",
    QUEUE: "QUEUE",
    FAILED: "FAILED",
    SEND: 'SEND',
    RESPOND: 'RESPOND',
    BOUNCE: 'BOUNCE',
    CLICK: 'CLICK',
    SPAM: 'SPAM',
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    UNDELIVERED: 'UNDELIVERED',
    DROP: 'DROP',
};

export const EVENT_TYPE = {
    SEQUENCE: "SEQUENCE",
    CAMPAIGN: "CAMPAIGN",
    EMAIL: "EMAIL",
};

export const SPREADSHEET_IMPORT_STEPS = {
    CONNECT: 0,
    CONFIGURE: 1,
    MAPPING: 2,
    DETAILS: 3,
    COMPLETE: 4,
};

export const CONNECTED_FILE_STEPS = {
    CONNECTED_FILE_LIST: 0,
    CONNECT_FILE: 1,
    SYNC_FILE: 3,
};

export const CSV_FILE_STEPS = {
    CSV_FILE_LIST: 0,
    CONNECT_FILE: 1,
};

export const CSV_IMPORT_STEPS = {
    UPLOAD: 0,
    MAPPING: 1,
    DETAILS: 2,
    COMPLETE: 3,
};

export const SPREADSHEET_PROCESSING_STATUS = {
    PROCESSED: "PROCESSED",
    NOT_PROCESSED: "NOT_PROCESSED",
};

export const CAMPAIGN_SETTINGS_TITLE = {
    LOOKUP_EMAIL: "LOOKUP_EMAIL"
}

export const USER_ROLE_ADMIN = 'ADMIN';
export const USER_ROLE_AGENT = 'AGENT';
export const USER_ROLE_OWNER = 'OWNER';


export const SORTING={
    ALPHABATIC_ASC:"A_TO_Z(ASC)",
    ALPHABATIC_DESC:"A_TO_Z(DESC)",
    DATE_TIME_ASC:"DATE_ADDED(ASC)",
    DATE_TIME_DESC:"DATE_ADDED(DESC)",
    ALPHABATIC:"ALPHABATIC",
    DATE_TIME:"DATE_TIME",
    DESC:'DESC',
    ASC:'ASC',
};

export const IMPORT_CONTACT_V2_CONTACT_DETAILS = [
    {
        value: 'first_name',
        label: 'First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'last_name',
        label: 'Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    { value: 'company_name', label: 'Company Name' },
    { value: 'deal_value', label: 'Price' },
    { value: 'lead_type', label: 'Lead Type' },
    { value: 'url', label: 'Url' },
    { value: 'tags', label: 'Tags' },
    { value: 'notes', label: 'Notes' },
    { value: 'birth_date', label: 'Birth Date' },
    { value: 'anniversary_date', label: 'Anniversary Date' },
];

export const IMPORT_CONTACT_V2_CONTACT_ADDRESS = [
    { value: 'address', label: 'Street Address' },
    { value: 'city', label: 'City' },
    { value: 'state', label: 'State' },
    {
        value: 'zip',
        label: 'Zip Code',
        guidelines: "We accept US and international ZIP and postal codes. US ZIP codes may include a 4-digit extension as long as they are separated by a hyphen.The extension is not required and will not further improve match rate.",
        examples: [
            '99950',
            '56586',
            '54865'
        ]
    },
    {
        value: 'country',
        label: 'Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    }
];

export const IMPORT_CONTACT_V2_COMMUNICATION_EMAIL = [
    {
        value: 'email',
        label: 'Primary Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email1',
        label: 'Email (1)',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email2',
        label: 'Email (2)',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email3',
        label: 'Email (3)',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email4',
        label: 'Email (4)',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    }
];

export const IMPORT_CONTACT_V2_RELATED_CONTACT1 = [
    {
        value: 'related_contact_1_first_name',
        label: 'Related Contact (1) First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'related_contact_1_last_name',
        label: 'Related Contact (1) Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    {
        value: 'related_contact_1_email',
        label: 'Related Contact (1) Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'related_contact_1_phone',
        label: 'Related Contact (1) Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'related_contact_1_address',
        label: 'Related Contact (1) Address',
    },
    {
        value: 'related_contact_1_city',
        label: 'Related Contact (1) City'
    },
    {
        value: 'related_contact_1_state',
        label: 'Related Contact (1) State'
    },
    {
        value: 'related_contact_1_zip',
        label: 'Related Contact (1) Zip'
    },
    {
        value: 'related_contact_1_country',
        label: 'Related Contact (1) Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    },
    {
        value: 'related_contact_1_relation',
        label: 'Related Contact (1) Relationship'
    }
];

export const IMPORT_CONTACT_V2_RELATED_CONTACT2 = [
    {
        value: 'related_contact_2_first_name',
        label: 'Related Contact (2) First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'related_contact_2_last_name',
        label: 'Related Contact (2) Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    {
        value: 'related_contact_2_email',
        label: 'Related Contact (2) Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'related_contact_2_phone',
        label: 'Related Contact (2) Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'related_contact_2_address',
        label: 'Related Contact (2) Address',
    },
    {
        value: 'related_contact_2_city',
        label: 'Related Contact (2) City'
    },
    {
        value: 'related_contact_2_state',
        label: 'Related Contact (2) State'
    },
    {
        value: 'related_contact_2_zip',
        label: 'Related Contact (2) Zip'
    },
    {
        value: 'related_contact_2_country',
        label: 'Related Contact (2) Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    },
    {
        value: 'related_contact_2_relation',
        label: 'Related Contact (2) Relationship'
    }
];

export const IMPORT_CONTACT_V2_RELATED_CONTACT3 = [
    {
        value: 'related_contact_3_first_name',
        label: 'Related Contact (3) First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'related_contact_3_last_name',
        label: 'Related Contact (3) Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    {
        value: 'related_contact_3_email',
        label: 'Related Contact (3) Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'related_contact_3_phone',
        label: 'Related Contact (3) Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'related_contact_3_address',
        label: 'Related Contact (3) Address',
    },
    {
        value: 'related_contact_3_city',
        label: 'Related Contact (3) City'
    },
    {
        value: 'related_contact_3_state',
        label: 'Related Contact (3) State'
    },
    {
        value: 'related_contact_3_zip',
        label: 'Related Contact (3) Zip'
    },
    {
        value: 'related_contact_3_country',
        label: 'Related Contact (3) Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    },
    {
        value: 'related_contact_3_relation',
        label: 'Related Contact (3) Relationship'
    }
];

export const IMPORT_CONTACT_V2_RELATED_CONTACT4 = [
    {
        value: 'related_contact_4_first_name',
        label: 'Related Contact (4) First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'related_contact_4_last_name',
        label: 'Related Contact (4) Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    {
        value: 'related_contact_4_email',
        label: 'Related Contact (4) Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'related_contact_4_phone',
        label: 'Related Contact (4) Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'related_contact_4_address',
        label: 'Related Contact (4) Address',
    },
    {
        value: 'related_contact_4_city',
        label: 'Related Contact (4) City'
    },
    {
        value: 'related_contact_4_state',
        label: 'Related Contact (4) State'
    },
    {
        value: 'related_contact_4_zip',
        label: 'Related Contact (4) Zip'
    },
    {
        value: 'related_contact_4_country',
        label: 'Related Contact (4) Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    },
    {
        value: 'related_contact_4_relation',
        label: 'Related Contact (4) Relationship'
    }
];

export const IMPORT_CONTACT_V2_RELATED_CONTACT5 = [
    {
        value: 'related_contact_5_first_name',
        label: 'Related Contact (5) First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'related_contact_5_last_name',
        label: 'Related Contact (5) Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    {
        value: 'related_contact_5_email',
        label: 'Related Contact (5) Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'related_contact_5_phone',
        label: 'Related Contact (5) Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'related_contact_5_address',
        label: 'Related Contact (5) Address',
    },
    {
        value: 'related_contact_5_city',
        label: 'Related Contact (5) City'
    },
    {
        value: 'related_contact_5_state',
        label: 'Related Contact (5) State'
    },
    {
        value: 'related_contact_5_zip',
        label: 'Related Contact (5) Zip'
    },
    {
        value: 'related_contact_5_country',
        label: 'Related Contact (5) Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    },
    {
        value: 'related_contact_5_relation',
        label: 'Related Contact (5) Relationship'
    }
];

export const IMPORT_CONTACT_V2_PRIMARY_PHONE = [
    {
        value: 'number',
        label: 'Primary Phone',
    },
    {
        value: 'number_type',
        label: 'Primary Phone Type',
    },
    {
        value: 'number_dnc',
        label: 'Primary Phone DNC'
    }
];

export const IMPORT_CONTACT_V2_SECONDARY_PHONE1 = [
    {
        value: 'number1',
        label: 'Phone (1)',
    },
    {
        value: 'number1_type',
        label: 'Phone (1) Type',
    },
    {
        value: 'number1_dnc',
        label: 'Phone (1) DNC'
    }
];

export const IMPORT_CONTACT_V2_SECONDARY_PHONE2 = [
    {
        value: 'number2',
        label: 'Phone (2)',
    },
    {
        value: 'number2_type',
        label: 'Phone (2) Type',
    },
    {
        value: 'number2_dnc',
        label: 'Phone (2) DNC'
    }
];

export const IMPORT_CONTACT_V2_SECONDARY_PHONE3 = [
    {
        value: 'number3',
        label: 'Phone (3)',
    },
    {
        value: 'number3_type',
        label: 'Phone (3) Type',
    },
    {
        value: 'number3_dnc',
        label: 'Phone (3) DNC'
    }
];

export const IMPORT_CONTACT_V2_SECONDARY_PHONE4 = [
    {
        value: 'number4',
        label: 'Phone (4)',
    },
    {
        value: 'number4_type',
        label: 'Phone (4) Type',
    },
    {
        value: 'number4_dnc',
        label: 'Phone (4) DNC'
    }
];